<template>
  <v-card>
    <v-card-title>
      Request Refund of {{ refundRequest.total | price }}
    </v-card-title>
    <v-card-text v-if="refundRequestData">
      <v-data-table
        :headers="tableColumns"
        :items="refundRequestData.items"
        class="text-no-wrap"
        :hide-default-footer="true"
      >
        <template #[`item.total`]="{item}">
          {{ item.total | price }}
        </template>
        <template #[`item.total`]="{item}">
          {{ item.order_item.total | price }}
        </template>
        <template #[`item.sku`]="{item}">
          {{ t(item.order_item.sku.snapshot.sku_name) }} -
          {{ t(item.order_item.sku.snapshot.product_title) }}
        </template>
      </v-data-table>
    </v-card-text>
    <v-card-actions>
      <template v-if="refundRequest.status === 'wait_for_acceptance'">
        <v-btn
          color="success"
          @click="confirmAcceptRefundRequest(refundRequest).then(() => $emit('close'))"
        >
          Accept
        </v-btn>
        <v-btn
          color="warning"
          @click="confirmRejectRefundRequest(refundRequest).then(() => $emit('close'))"
        >
          Reject
        </v-btn>
      </template>

      <template v-else>
        This Request has been {{ refundRequest.status }}.
      </template>
    </v-card-actions>
  </v-card>
</template>

<script>
import { price } from '@core/utils/filter'
import { t } from '@/plugins/i18n'
import { ref } from '@vue/composition-api'
import useRefundRequest from './useRefundRequest'

export default {
  filters: { price },
  props: {
    refundRequest: {
      type: Object,
      required: true,
    },
  },
  setup(props) {
    const { loadRefundRequest, refundRequestData, confirmAcceptRefundRequest, confirmRejectRefundRequest } = useRefundRequest()

    loadRefundRequest(props.refundRequest.id)

    const tableColumns = [
      { text: 'Refund Amount', value: 'total' },
      { text: 'Items Total', value: 'total' },
      { text: 'SKU', value: 'sku' },
    ]

    return {
      tableColumns,
      refundRequestData,
      t,

      confirmAcceptRefundRequest,
      confirmRejectRefundRequest,
    }
  },
}
</script>
