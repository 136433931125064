import { withOptions } from '@/assets/js/utils'
import { fetchRefundRequests } from '@api/order'
import { ref, watch } from '@vue/composition-api'

const cached = []

export default function useRefundRequestList() {
  const listTable = ref([])

  // Table Handlers
  const tableColumns = [
    {
      text: '#ID',
      align: 'start',
      value: 'id',
    },
    { text: 'CREDITS TOTAL', value: 'credits_total' },
    { text: 'REFUND TOTAL', value: 'total' },
    { text: 'PAYMENT AMOUNT', value: 'payment', sortable: false },
    { text: 'DATE', value: 'created_at' },
    { text: 'STATUS', value: 'status' },
    {
      text: 'ACTIONS',
      value: 'actions',
      align: 'center',
      sortable: false,
    },
  ]

  const options = ref(
    cached[0] || {
      sortBy: ['id'],
      sortDesc: [true],
    },
  )
  const searchQuery = ref(cached[1] || '')
  const totalListTable = ref(0)
  const loading = ref(false)
  const statusFilter = ref(null)
  const selectedTableData = ref([])

  const loadRefundRequests = () =>
    fetchRefundRequests(
      withOptions(options.value, {
        search: searchQuery.value,

        // options: options.value,
        status: statusFilter.value,
      }),
    )
      .then(({ data }) => {
        const { records, pagination } = data.data
        listTable.value = records
        totalListTable.value = pagination.total
        loading.value = false
      })
      .catch(error => {
        console.log(error)
      })

  loadRefundRequests()

  const cachable = [options, searchQuery, statusFilter]

  const cache = () => {
    cachable.forEach((variable, index) => {
      cached[index] = variable.value
    })
  }

  cache()

  watch(cachable, () => {
    const newPage = options.value.page

    // reset page if filter others (i.e. no page change)
    if (newPage >= 2 && newPage === cached[0].page) {
      options.value.page = 1
    }

    loading.value = true
    loadRefundRequests()
    cache()
  })

  return {
    tableColumns,
    searchQuery,
    options,
    listTable,
    statusFilter,
    totalListTable,
    loading,
    selectedTableData,
    loadRefundRequests,
  }
}
